export const env = {
  BASE_COST: 1,
  TREASURY_WALLET: "TWtgPVjzGtkRTErV66ZttBSt8UxPRxaznr",
  COST_PER_ADDITIONAL_FEATURE: 0,
  FIREBASE_API_KEY: "AIzaSyBwM538qc9X6NQTAqai_US3VgZNFvFhaT0",
  FIREBASE_AUTH_DOMAIN: "tnft-maker.firebaseapp.com",
  FIREBASE_PROJECT_ID: "tnft-maker",
  FIREBASE_STORAGE_BUCKET: "tnft-maker.appspot.com",
  FIREBASE_MESSAGING_SENDER_ID: "515357344144",
  FIREBASE_APP_ID: "1:515357344144:web:19a8185c067907b8937315",
  FIREBASE_MEASUREMENT_ID: "G-K4WQRFJXL4",
};
